<template>
  <section class="container-xl" v-if="minimalModeActive">
    <div v-for="product in allProducts" :key="product.number" class="item-wrapper">
      <ProductItemBig :product="product" />
    </div>
  </section>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import ProductItemBig from '@/components/Product/ProductItemBig.vue';
  import loadCouponing from '@/helpers/couponing';

  export default {
    components: { ProductItemBig },
    computed: {
      ...mapState({
        allProducts: (state) => state.product.catalogProducts,
        shopCustomization: (state) => state.basket.shopCustomization,
        websiteSettings: (state) => state.app.websiteSettings
      }),
      ...mapGetters({
        minimalModeActive: 'pages/minimalMode'
      }),
    },
    beforeMount() {
      this.$store.commit('app/SET_HEADER_LOGGEDIN', true);
      if(this.shopCustomization) this.$setColors(this.shopCustomization.primary_color, this.shopCustomization.secondary_color);
      if(this.websiteSettings.couponing_enable && this.websiteSettings.couponing_location === 'welcome') {
        loadCouponing({
          sessionId: this.$store.state.basket.basketNumber,
          sourceNumber: this.websiteSettings.couponing_source_number,
          mediumNumber: this.websiteSettings.couponing_medium_number
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .item-wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
</style>
