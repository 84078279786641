export default function loadCouponing(data) {  
  window.sovIframes = window.sovIframes || [];

  window.sovIframes.push({
    trafficSourceNumber: data.sourceNumber,
    trafficMediumNumber: data.mediumNumber,
    sessionID: data.sessionID,
    timestamp: Math.floor(Date.now() / 1000),
    integrationType: "genericScript-1.3.3"
  });

  // Append Sovendus script to the head
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src =
    window.location.protocol +
    "//api.sovendus.com/sovabo/common/js/flexibleIframe.js";
  document.head.appendChild(script);
}