<template>
  <section class="confirmation">
    <div v-if="orderNumber" class="container-xl mb-5">
      <div class="row">
        <div class="col-md-8 col-xl-7">
          <DefaultCard
            :title="$t('view.checkout.confirmation.info.headline')"
            :text="$t('view.checkout.confirmation.info.text')"
            bg-variant="success"
            text-variant="success"
            soft-color
          >
            <div class="slot-container">
              <span class="semibold"> {{ $t('view.checkout.confirmation.info.order_number') }}: </span>
              <a
                :href="companyUrls.order_status_rendered + `?email_name_firm=${invoiceEmail}&receipt_number=${orderNumber}`"
                rel="noopener"
                target="_blank"
                class="orderLink"
                >{{ orderNumber }}</a
              >
            </div>
          </DefaultCard>
          
          <div class="card-spacer--top">
            <DefaultCard bordered v-for="(affiliate, index) in receiptPosition.slice(0, 2)" :key="index" :class="{'mt-3' : index > 0}">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="affiliate__image-container mr-3">
                    <div class="affiliate__image">
                      <img class="product__image" :src="affiliate?.bakset_images[0] ? `https://images.cadooz.com/${affiliate.bakset_images[0]}` : null" alt="">
                    </div>
                  </div>
                  <div>
                    <h5 class="bold">{{ affiliate.product_name }}</h5>
                    <p class="m-0">{{ $t('view.checkout.confirmation.afiliate.value', { value: $currency(getProductPrice(affiliate), getCurrencySymbol(affiliate)) }) }}</p>
                  </div>
                </div>

                <div v-if="affiliate.affiliate_link">
                  <a :href="affiliate.affiliate_link" class="affiliate_gift_links" target="_blank">{{ $t('view.checkout.confirmation.afiliate.link_to') }}</a>
                </div>
              </div>
            </DefaultCard>

            <div v-if="receiptPosition.length > 2" class="text-center mb-5 mt-4">
              <button class="btn btn-light open_modal_btn" @click="showModal = true">
                {{ $t('view.checkout.confirmation.afiliate.open_modal') }}
                <b-icon icon="chevron-right" class="ml-2 modal_btn_icon"></b-icon>
              </button>
            </div>
          </div>


          <AffiliateModal 
            :title="$t('view.checkout.confirmation.afiliate.modal_title')"
            :affiliate_link="$t('view.checkout.confirmation.afiliate.link_to')"
            :visible="showModal"
            :items="receiptPosition"
            @button-click="showModal = false"
          />

          <DefaultCard class="card-spacer--top" :title="this.$t('view.checkout.confirmation.hints.headline')" bordered>
            <ul>
              <li v-for="(instruction, index) in instructions" :key="index" class="my-2">{{ instruction.text }}</li>
            </ul>
            <p class="semibold margin-left">{{ $t('view.checkout.confirmation.hints.signature') }}</p>
          </DefaultCard>
          <DefaultCard
            v-if="containsPostPayment && !this.getSaferPayMethod"
            :title="$t('view.checkout.confirmation.prepay.headline')"
            :text="paymentInformations.text"
            class="card-spacer--top"
            bordered
          >
            <div v-for="information in computedPaymentInformations" :key="information.title">
              <div class="d-flex justify-content-between">
                <span>{{ information.title }}:</span>
                <span>{{ information.content }} </span>
              </div>
              <hr class="divider" />
            </div>
          </DefaultCard>
          <DefaultCard
            v-if="voucherInformations.length"
            :title="$t('view.checkout.confirmation.voucher.headline')"
            :text="$t('view.checkout.confirmation.voucher.text')"
            class="card-spacer--top"
            bordered
          >
            <div v-for="voucher in voucherInformations" :key="voucher.code">
              <hr />
              <h5 class="semibold">{{ voucher.code }}</h5>
              <div class="d-flex justify-content-between">
                <span>{{ $t('view.checkout.payment.voucher_list.amount_used') }}</span>
                <span> {{ $currency(voucher.usedValue) }} </span>
              </div>
              <div class="d-flex justify-content-between">
                <span>{{ $t('view.checkout.payment.voucher_list.amount_rest') }}</span>
                <span> {{ $currency(voucher.remainingValue) }}</span>
              </div>
            </div>
          </DefaultCard>
          <div class="row">
            <div class="card-spacer--top col-12 col-md-6" cols="12" md="6">
              <DefaultCard
                :title="$t('view.checkout.confirmation.status.headline')"
                :text="$t('view.checkout.confirmation.status.text')"
                class="h-100"
                bordered
                card-s-m
              >
                <a class="font-sm semibold link-position" :href="companyUrls.order_status" rel="noopener" target="_blank">
                  {{ $t('view.checkout.confirmation.status.link') }}</a
                >
              </DefaultCard>
            </div>
            <div class="card-spacer--top col-12 col-md-6">
              <DefaultCard
                :title="$t('view.checkout.confirmation.service.headline')"
                :text="$t('view.checkout.confirmation.service.text')"
                class="h-100"
                bordered
                card-s-m
              >
                <a class="font-sm semibold link-position" :href="companyUrls.service[$store.state.translation.locale === 'de' ? 'de' : 'en']" rel="noopener" target="_blank">
                  {{ $t('view.checkout.confirmation.service.link') }}</a
                >
              </DefaultCard>
            </div>
          </div>
          <DefaultCard
            v-if="surveyMonkey && surveyMonkey.length"
            soft-color
            button-variant="info"
            bg-variant="info"
            class="card-spacer--top"
            :title="$t('view.checkout.confirmation.survey.headline')"
            :text="$t('view.checkout.confirmation.survey.text')"
          >
            <DefaultButton
              type="url"
              :href="surveyMonkey"
              variant="info"
              :button-text="$t('view.checkout.confirmation.survey.button')"
              target="_blank"
            />
          </DefaultCard>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <!-- TODO: Dummy Text durch Text vom PO ersetzen -->
      <DefaultCard
        title="Keine Bestellung gefunden"
        text="Falls Sie gerade eine Bestellung getätigt haben überprüfen Sie bitte Ihre E-Mails auf eine Bestellbestätigung."
      />
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import loadCouponing from '@/helpers/couponing';
  import DefaultCard from 'building-blocks/components/DefaultElements/DefaultCard.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import companyUrls from '@/config/companyUrls.js';
  import AffiliateModal from '../../components/AffiliateModal.vue';
  import BIcon from '../../../../building-blocks/components/BIcon.vue';

  export default {
    layout: 'logged-out',
    components: {
      DefaultCard,
      DefaultButton,
      AffiliateModal,
      BIcon
    },
    data() {
      return {
        showModal: false,
        companyUrls: companyUrls,
      };
    },
    props: {
      icon: {
        type: String,
        default: 'arrow-right'
      },
    },
    computed: {
      ...mapGetters({
        invoiceEmail: ['order/getInvoiceEmail'],
        containsPostPayment: ['order/containsPostPayment'],
        getSaferPayMethod: ['order/getSaferPayMethod']
      }),
      ...mapState({
        orderNumber: (state) => state.order.orderNumber,
        containsOnlineProduct: (state) => state.order.containsOnlineProducts,
        orderGrossPrice: (state) => state.order.grossPrice,
        invoiceAmount: (state) => state.order.amountToPay,
        trackingValue: (state) => state.order.trackingValue,
        receiptPosition: (state) => state.order.receiptPosition,
        mgnlMode: (state) => state.app.mgnlMode,
        websiteSettings: (state) => state.app.websiteSettings
      }),
      surveyMonkey() {
        return this.$store.state.pages.additionalOptions.surveyMonkey;
      },
      paymentInformations() {
        return {
          content: [
            {
              title: this.$t('view.checkout.confirmation.prepay.owner'),
              content: 'cadooz GmbH'
            },
            {
              title: this.$t('view.checkout.confirmation.prepay.bank_name'),
              content: 'Deutsche Bank AG'
            },
            {
              title: this.$t('view.checkout.confirmation.prepay.account_number'),
              content: '070730703'
            },
            {
              title: this.$t('view.checkout.confirmation.prepay.bank_code'),
              content: '20070000'
            },
            {
              title: this.$t('view.checkout.confirmation.prepay.iban'),
              content: 'DE 30 2007 0000 0070 7307 03'
            },
            {
              title: this.$t('view.checkout.confirmation.prepay.bic'),
              content: 'DEUTDEHHXX'
            }
          ]
        };
      },
      instructions() {
        const instructions = [{ text: this.$t('view.checkout.confirmation.hints.option_inbox') }];
        if (this.containsOnlineProduct) {
          instructions.push({ text: this.$t('view.checkout.confirmation.hints.option_email') });
        }
        if (this.containsPostPayment && !this.getSaferPayMethod) {
          instructions.push({ text: this.$t('view.checkout.confirmation.hints.option_prepay') });
        }
        return instructions;
      },
      computedPaymentInformations() {
        const paymentInformations = this.paymentInformations.content;
        const intendedUse = { title: this.$t('view.checkout.confirmation.prepay.purpose'), content: this.orderNumber };
        const invoiceAmount = {
          title: this.$t('view.checkout.confirmation.prepay.transfer_amount'),
          content: this.$currency(this.invoiceAmount)
        };
        if (!paymentInformations.includes(intendedUse)) {
          paymentInformations.push(intendedUse);
        }
        if (!paymentInformations.includes(invoiceAmount)) {
          paymentInformations.push(invoiceAmount);
        }
        return paymentInformations;
      },
      voucherInformations() {
        const voucherInformations = [];
        const prepaidPayments = this.$store.getters['order/getPrepaidPayments'];
        prepaidPayments.forEach((prepaidPayment) => {
          const voucher = {
            code: prepaidPayment.code,
            remainingValue: prepaidPayment.voucher_remaining_value,
            usedValue: prepaidPayment.value
          };
          voucherInformations.push(voucher);
        });
        return voucherInformations;
      },
      activeGiftomatic() {
        return this.$store.getters['pages/activeGiftomatic'];
      },
    },
    created() {
      if(!this.orderNumber && !this.mgnlMode) this.$router.push('/');
    }, 
    mounted() {
      const toastData = {
        title: this.$t('view.checkout.confirmation.survey.headline'),
        message: this.$t('view.checkout.confirmation.survey.text'),
        colorVariant: 'info',
        buttonText: this.$t('view.checkout.confirmation.survey.button'),
        link: this.surveyMonkey
      };

      if (this.surveyMonkey && this.surveyMonkey.length) {
        this.$store.commit('app/SET_TOAST', toastData);
      }
      
      this.adaptHeader();
      this.eTrackerCommerceOrder();

      if(this.activeGiftomatic) {
        this.optimizedLinks();
      }

      if(this.websiteSettings.couponing_enable && this.websiteSettings.couponing_location === 'confirmation') {
        loadCouponing({
          sessionId: this.$store.state.basket.basketNumber,
          sourceNumber: this.websiteSettings.couponing_source_number,
          mediumNumber: this.websiteSettings.couponing_medium_number
        });
      }
    },
    methods: {
      // Adapt header on confirmation page because header need logged-out class
      adaptHeader() {
        const header = document.querySelector('#header');
        if (header) {
          const parent = header.parentElement;
          if (this.mgnlMode) {
            header.classList.remove('sticky-header');
          }
          const id = header.getAttribute('id');
          const cssClass = header.getAttribute('class');
          parent.setAttribute('id', id);
          parent.setAttribute('class', cssClass);
          header.removeAttribute('id');
          header.removeAttribute('class');
        }
      },
      eTrackerCommerceOrder() {
        if(!this.$store.state.etracker.etrackerId) return;

        const products = this.receiptPosition.map(prod => {
          const price = prod.tracking_value;
          const discount = prod.discount_percantage ? price*(prod.discount_percantage/100) : 0;
          const categories = this.$store.getters['etracker/getProductHierarchy'](prod.product_number);
          const isReward = this.isReward(prod.product_number);
          return {
            product: { 
              id: prod.product_number, 
              name: prod.tracking_name, 
              price: price.toFixed(2).toString(), 
              discount: discount.toFixed(2).toString(),
              currency: 'EUR', 
              category: categories, 
              variants: isReward ? {} : {'var1': prod.face_value.amount.toFixed(2).toString()}
            }, 
            quantity: prod.quantity
          }
        });
          
        const order = {
          orderNumber: this.orderNumber, 
          status: 'sale', 
          orderPrice: this.trackingValue.toFixed(2).toString(), 
          currency: 'EUR', 
          basket: {id: this.orderNumber, products}
        };

        this.$store.dispatch('etracker/etCommerce', {eventName: 'order', payload: order});
      },
      getProductPrice(prod) {
        let price = prod.face_value.amount;
        const product = this.$store.getters['product/getProduct'](prod.product_number);
        const isReward = this.isReward(prod.product_number);
        if(isReward) {
          price = product.recommended_retail_price;
        } else if (product.external_product_value_type_id === 'HID' && product.variations.length === 1) {
          price = prod.gross_price;
        }
        return price;
      },
      isReward(product_number) {
        return this.$store.getters['product/getProductTypReward'](product_number);
      },
      getCurrencySymbol(productPosition) {
        return this.isReward(productPosition.product_number) ? this.$store.state.app.currency.symbol : productPosition.face_value.currency.short_symbol || productPosition.face_value.currency.symbol
      },
      optimizedLinks() {
        const giftomaticLinks = document.querySelectorAll('.affiliate_gift_links');
        giftomaticLinks.forEach(el => {
          el.addEventListener('click', element => {
            if (!element.target.classList.contains('link-optmz')) {
              element.preventDefault();
              element.target.href = 'https://www.giftomatic.io/out?dataset=CADOOZ&url=' + encodeURIComponent(el.href);
              element.target.classList.add('link-optmz');
              element.target.click();
            }
          })
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
  .confirmation {
    margin-top: 3rem;
  }

  .slot-container {
    padding: 1rem;
    border-radius: $border-radius;
    background-color: $light;
    color: var(--dark);
    max-width: max-content;
    a {
      color: var(--dark);
    }
  }

  .semibold {
    font-family: $font-semibold;
  }
  .bold {
    font-family: $font-bold;
  }
  .open_modal_btn {
    border: 1px solid var(--primary);

    &:hover {
      .modal_btn_icon {
        fill: var(--light);
      }
    }
  }
  .modal_btn_icon {
    fill: var(--primary);
    vertical-align: middle !important;
  }
  .margin-left {
    margin-left: 1.5rem;
  }
  .divider {
    margin: 0.7rem 0 0.7rem 0;
  }
  .card-spacer--top {
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      margin-top: 2rem;
    }
  }
  .affiliate__image-container,
    .affiliate__image {
      width: 5rem;
      height: 3.2rem;
      position: relative;
      @include media-breakpoint-down(xs) {
        width: 3rem;
      }
    }
    .affiliate__image {
      z-index: 1;
      position: absolute;
      height: 100%;
      overflow: hidden;
      cursor: pointer;
      .product__image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
      }
    }
</style>
