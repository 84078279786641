<template>
  <article class="mb-3">
    <router-link :to="$routeHandler(`/product-details/${product.number}`)" class="product-link">
      <div class="card product animated-border">
        <div class="card-body">
          <div class="product__image-container">
            <img
              :src="imageUrl"
              :alt="product.name"
              class="product__image-container__image"
            />
          </div>
          <div class="product__data">
            <div class="product__type">{{ getProductType(product.type_group) }}</div>
            <div class="product__name">{{ product.name }}</div>
            <div class="price-area">
              <div class="product__price-wrap">
                <div v-if="isReward" class="product__price">{{ $currency(firstVarSellingPrice) }}</div>
                <div v-if="isReward && hasDiscount" class="product__old-price">{{ $currency(retailPrice) }}</div>
                <div v-else-if=" product.variations.length && firstVarSellingPrice < lastVarSellingPrice" class="product__price">
                  {{ $currency(firstVarSellingPrice, getCurrencySymbol(product)) }} -
                  {{ $currency(lastVarSellingPrice, getCurrencySymbol(product)) }}
                </div>
                <div v-else-if="!isReward" class="product__price">{{ $currency(firstVarSellingPrice, getCurrencySymbol(product)) }}</div>
              </div>
            </div>
          </div>
          <div class="product__badge--fixed" v-if="hasDiscount || product.discount_text">
            <div v-if="product.discount_text" class="d-flex mb-1">
              <span class="badge badge-primary product__discount">{{ product.discount_text }}</span>
            </div>
            <div v-if="hasDiscount" class="d-flex">
              <span class="badge badge-secondary product__discount">-{{ product.displayed_discount }}%</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </article>
</template>
<script>
  export default {
    props: {
      product: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    data() {
      return {
        imageServerUrl: 'https://images.cadooz.com/',
        firstVarSellingPrice: 0,
        lastVarSellingPrice: 0,
        retailPrice: 0
      }
    },
    computed: {
      isReward() {
        return this.product.type_group === 'PHY';
      },
      isB2B() {
        return this.$store.state.app.isB2B;
      },
      imageUrl() {
        const { product, imageServerUrl } = this;
        let imgUrl = product.big_images[0];
        if (!imgUrl) {
          imgUrl = product.gift_card_image;
        }
        return `${imageServerUrl}${imgUrl}`;
      },
      discountPercentage() {
        return this.product.displayed_discount;
      },
      hasDiscount() {
        return this.discountPercentage > 0;
      }
    },
    methods: {
      getProductType(type) {
        switch(type) {
          case 'TVO':
            return this.$t('filter.group.type.option_vouchers');
          case 'PHY':
            return this.$t('filter.group.type.option_brand_products');
          case 'UVO':
            return this.$t('filter.group.type.option_theme_vouchers');
          default:
            return '';
        }
      },
      getVariationSellingPrice(product, varIndex) {
        let price = product.variations[varIndex][(this.isB2B ? 'net_' : '') + 'selling_price'];
        if(!this.isReward && product.external_product_value_type_id !== 'HID' || product.variations.length > 1) {
          price = product.variations[varIndex].face_value.amount;
        }
        return price;
      },
      getCurrencySymbol(product) {
        return product.variations[0]?.face_value?.currency?.short_symbol || product.variations[0]?.face_value?.currency?.symbol;
      }
    },
    created() {
      this.firstVarSellingPrice = this.getVariationSellingPrice(this.product, 0);
      this.lastVarSellingPrice = this.getVariationSellingPrice(this.product, this.product.variations.length - 1);
      this.retailPrice = this.isB2B ? this.product.net_recommended_retail_price : this.product.recommended_retail_price;
    }
  }
</script>

<style lang="scss" scoped>
  .product-link:hover .product {
    box-shadow: $box-shadow;
  }
  .product {
    box-shadow: $box-shadow-sm;
    width: 100%;
    border-radius: $border-radius;
    transition: all 0.15s ease-in;

    &__data {
      max-width: 47%;
      width: 100%;
      padding: .75rem .5rem .75rem 0;
    }
    .card-body {
      position: relative;
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
    &__image-container {
      position: relative;
      width: 100%;
      max-width: 47%;
      padding-top: 30%;

      &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
      }
    }
    &__name {
      color: $body-color;
      margin-bottom: .5rem;
      font-family: $font-semibold;
      font-size: .9rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__type {
      margin-bottom: .25rem;
      font-weight: bold;
      color: var(--primary);
      font-size: .8rem;
    }
    &__badge {
      margin-bottom: 0.5rem;
    }
    &__price,
    &__old-price {
      @extend %font-h4;
      font-family: $font-semibold;
    }
    &__price {
      color: var(--secondary);
    }
    &__old-price {
      color: $gray_700;
      text-decoration: line-through;
      text-align: right;
    }
  }
  .product__badge--fixed {
    position: absolute;
    top: -.5rem;
    left: -.5rem;
    z-index: 3;

    .badge {
      padding: .5rem;
      font-size: 1rem;
    }
  }
</style>
